@import url("https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body,
html {
  position: relative;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "El Messiri";
}

h6,
p {
  font-family: "Montserrat";
}

.navbar {
  min-height: 100px;
  background: transparent;
}

nav.shrink {
  min-height: 100px;
  background: rgb(9, 5, 36);
}

nav.shrink.navbar a {
  filter: brightness(100%);
  -webkit-filter: brightness(100%);
}
.navbar-transition {
  transition: background-color 0.5s ease;
}

.navbar-toggler {
  background: rgb(9, 5, 36);
}

.nav-link {
  text-transform: uppercase;
  color: white;
}

.navbar-toggler span {
  display: block;
  width: 25px;
  height: 3px;
  background: white;
  margin: 5px 0px;
}

.navbar button {
  margin-top: 0px;
}

.navbar-nav li a {
  color: white;
  font-family: "Tamil MN";
  font-size: 18px;
  margin-left: 5px;
  filter: brightness(100%);
  -webkit-filter: brightness(100%);
}

@media (min-width: 576px) and (max-width: 992px) {
  .navbar-nav li a {
    font-size: 13px !important;
    margin: 0px;
  }
}

.navbar-nav li a:hover {
  filter: brightness(100%);
  color: white;
  width: auto;
  -webkit-filter: brightness(100%);
}

.navbar a {
  color: white;
  font-family: copperplate;
  font-size: 30px;
  margin-left: 5px;
  filter: brightness(100%);
  -webkit-filter: brightness(100%);
}
.navbar a:hover {
  filter: brightness(100%);
  color: white;
  width: auto;
  -webkit-filter: brightness(100%);
}

#topBtn {
  position: fixed;
  bottom: 40px;
  opacity: 1;
  left: 10px;
  font-size: 22px;
  width: 40px;
  height: 40px;
  background: darkslategray;
  color: whitesmoke;
  border: none;
  cursor: pointer;
  display: none;
  border-radius: 50%;
}

#topBtn:hover {
  position: fixed;
  bottom: 40px;
  opacity: 0.5;
  left: 10px;
  font-size: 22px;
  width: 40px;
  height: 40px;
  background: darkslategray;
  color: whitesmoke;
  border: none;
  cursor: pointer;
  display: none;
  padding: 0px;
}

@media (max-width: 992px) {
  #topBtn {
    width: 30px;
    height: 30px;
    padding: 0px;
  }

  #topBtn:hover {
    width: 30px;
    height: 30px;
    padding: 0px;
  }
}

.carousel-inner img {
  width: 100%;
  height: 830px;
}

@media (max-width: 992px) {
  .carousel-inner img {
    width: 100%;
    height: 650px;
    object-fit: cover;
  }
}

.carousel-caption a {
  border-radius: 20px;
  padding: 8px;
  background: rgba(243, 58, 12, 0.904);
  color: white;
  font-family: times;
  width: 150px;
  height: 45px;
  font-weight: bold;
}

.carousel-caption a:hover {
  color: rgba(243, 58, 12, 0.904);
  background: white;
}

.carousel-caption a:hover:after {
  content: "\2192";
}

/** For Round indicators */
/* 
.carousel-indicators--round li {
  width: 30px;
  height: 10px;
  border-radius: 10px;
  margin: 1px 3px;
  border: 1px solid #fff;
  background-color: transparent;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.carousel-indicators .ttkk {
  max-width: 12px;
  height: 12px;
  margin: 0px 3px;
  background-color: #ccc;
  filter: brightness(50%);
  -webkit-filter: brightness(50%);
}

.carousel-indicators .active {
  filter: brightness(100%);
  -webkit-filter: brightness(100%);
} */

#carotext {
  margin-bottom: 250px;
  font-family: "El Messiri";
}
#serve {
  background: whitesmoke;
  height: 260px;
}

#serve a {
  text-decoration: none;
}

#serve i {
  color: crimson;
  font-size: 25px;
  cursor: pointer;
}

#serve i:hover {
  color: orange;
  transform: scale(1.2);
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

#serve h5 {
  margin-top: 20px;
  font-family: Georgia, "Times New Roman", Times, serif;
  text-transform: uppercase;
  color: darkblue;
}

#serve p {
  font-size: 17px;
  margin-top: 30px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

#serve:hover {
  background: white;
  transform: scale(1.1);
  transition: 1s;
  box-shadow: 0px 2px 10px 3px gray;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.img-frame {
  background: whitesmoke;
  padding: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.oserve {
  color: white;
  font-size: 35px;
  font-family: "El Messiri";
  /* text-shadow: 3px 3px 3px gray; */
}
.bado {
  text-align: center;
  margin-top: 10px;
}
.bado h4 {
  color: white;
  font-size: 30px;
  font-family: "orbitron";
  text-shadow: 3px 3px 5px black;
}

.bado p {
  color: white;
  font-size: 17px;
  font-family: "abel";
  text-shadow: 3px 3px 5px black;
}

#cardo i {
  color: white;
  font-size: 50px;
  float: right;
}

#cardo {
  background: rgb(255, 136, 0);
}

#cardo:hover {
  background: crimson;
  color: white;
}

#net a {
  text-decoration: none;
}

#net div li {
  cursor: pointer;
  color: black;
  list-style: none;
  font-size: 18px;
  text-transform: uppercase;
  font-family: "Oswald";
}

#net div li:hover {
  color: white;
}

@media (max-width: 990px) {
  #net a li {
    color: black;
    list-style: none;
    font-size: 12px;
  }
}

#team {
  background: white;
  padding: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.rounded-circle {
  background: transparent;
  padding: 2px;
  box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#team img {
  position: absolute;
  border-radius: 50%;
  top: -40px;
  left: 40px;
}

#team h4 {
  font-family: "Oswald";
}

#team h6 {
  font-family: "nunito";
  color: darkslategray;
}

#team p {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: darkslategray;
}

.oteam {
  color: white;
  font-size: 35px;
  font-family: "El Messiri";
  text-shadow: 3px 3px 3px black;
}

.oteam-new {
  color: #057e8c;
  font-size: 35px;
  font-family: "El Messiri";
  /* text-shadow: 3px 3px 3px black; */
}

.dess {
  /* color: rgb(189, 39, 69); */
  color: rgba(243, 58, 12, 0.904);
  font-size: 40px;
}

.vertline {
  margin-top: 20px;
  margin-bottom: 20px;
  border-left: 2px solid rgb(160, 169, 177);
  height: auto;
}

.iconz {
  font-size: 60px;
  color: crimson;
  margin-top: 30px;
  margin-bottom: 20px;
}

.jojo {
  background: crimson;
  float: right;
  color: white;
  width: 150px;
  margin-right: 40px;
}

.jojo:hover {
  background: transparent;
  color: white;
  border: 2px solid darkblue;
}

.comz h4 a {
  text-decoration: none;
  color: darkmagenta;
}

.dude input {
  height: 50px;
  font-size: 18px;
  font-family: "Times New Roman", Times, serif;
}

.didi {
  font-size: 24px;
}

.footz {
  text-align: center;
  background: rgb(9, 5, 36);
  height: auto;
  color: white;
}

.footz li {
  list-style: none;
}

.footz li a {
  text-decoration: none;
  color: wheat;
  font-family: "abel";
}

.footz li a:hover {
  color: orange;
}

#sit {
  font-family: "abel";
  font-size: 20px;
}

.img-prod .card {
  width: 100%;
  height: 340px;
  box-shadow: 0 4px 5px 10px rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.img-prod img {
  width: 100%;
  height: 300px;
}

.img-prod img:hover {
  transform: scale(1.12);
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  -webkit-transform: scale(1.12);
  -moz-transform: scale(1.12);
  -ms-transform: scale(1.12);
  -o-transform: scale(1.12);
}

.prod h4 {
  color: darkslategray;
  font-size: 30px;
  margin-top: 40px;
  font-family: "El Messiri";
}

.prod p {
  color: darkslategray;
  font-family: "abel";
  font-size: 20px;
  margin-top: 30px;
}

.img-prodo .card {
  width: 100%;
  height: 340px;
  box-shadow: 0 4px 5px 10px rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.img-prodo img {
  width: 100%;
  height: 300px;
}

.img-prodo img:hover {
  transform: scale(1.12);
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  -webkit-transform: scale(1.12);
  -moz-transform: scale(1.12);
  -ms-transform: scale(1.12);
  -o-transform: scale(1.12);
}

.prodo h4 {
  color: darkslategray;
  font-size: 30px;
  margin-top: 40px;
}

.prodo p {
  color: darkslategray;
  font-family: "pacificio";
  font-size: 20px;
  margin-top: 30px;
}

.ready h3 {
  color: darkslategray;
  font-size: 40px;
  font-family: "abel";
}

.ready h4 {
  color: slategray;
  font-size: 30px;
  font-family: "pacificio";
}

.ready button {
  background: rgba(11, 11, 112, 0.849);
  color: white;
  height: 45px;
  width: 280px;
}

.ready button:hover {
  transform: scale(1.12);
  transition: 0.8s;
  -webkit-transition: 0.8s;
  -moz-transition: 0.8s;
  -ms-transition: 0.8s;
  -o-transition: 0.8s;
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -o-transform: scale(1.15);
}

.oprod {
  color: darkmagenta;
  font-size: 35px;
  font-family: "El Messiri";
  text-shadow: 5px 5px 8px rgb(156, 155, 155);
}

.prodess {
  color: gold;
  font-size: 40px;
}

.apple {
  color: gray;
  font-size: 40px;
}

.android {
  color: greenyellow;
  font-size: 40px;
}

.limit {
  display: block;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.spinner {
  width: 40px;
  height: 40px;
  background-color: white;
  position: absolute;
  top: 48%;
  left: 48%;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.spinner-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(9, 5, 36);
  z-index: 999999;
}

.iconSize {
  width: 30px;
  height: 30px;
}

.preservCard {
  position: relative;
  width: 266px;
  height: 200px;
  background-color: rgba(8, 8, 8, 0.04);
  border-radius: 4px;
  color: black;
  padding-left: 30px;
  transition: box-shadow 0.3s ease; /* Adding transition for smooth hover effect */
}

/* // Small devices (landscape phones, 576px and up) */
/* @media (max-width: 820px) {
  .preservCard {
    position: relative;
    width: 266px;
    height: 200px;
  }
} */

.preservCard:hover {
  background-color: #ffffff;
  box-shadow: 0px 10px 20px rgba(9, 5, 36, 0.5); /* Adding box shadow on hover */
}

.preservCard div {
  width: fit-content;
  height: fit-content;
  padding: 10px;
  border-radius: 50%;
  background-color: #ffffff;
}

.preservCard p {
  position: absolute;
  bottom: 0;
  right: 30px;
}

.preservCard:hover h3 {
  color: darkblue;
}

.hireCard {
  position: relative;
  width: 100%;
  height: auto;
  background-color: #090524;
  border-radius: 4px;
}

/* // Small devices (landscape phones, 576px and up) */
@media (max-width: 820px) {
  .hireCard {
    margin-top: 20px;
    position: relative;
    width: 80%;
    height: 300px;
    background-color: #090524;
    border-radius: 4px;
  }
}

.hireCard h3 {
  position: absolute;
  bottom: 100px;
  left: 50px;
  text-transform: capitalize;
}

.hireCard h4 {
  position: absolute;
  bottom: 30px;
  left: 50px;
}

.hireCard h4 strong a {
  color: #ffffff;
  text-decoration: none;
  padding-left: 10px;
  font-family: "Montserrat" !important;
  font-weight: 400;
}

.hireCard p {
  position: absolute;
  top: 30px;
  right: 30px;
  font-family: "Montserrat" !important;
}

.whatSize {
  width: 20px;
  height: 20px;
}

.remake {
  padding-top: 50px;
  padding-bottom: 80px;
}

.testy {
  position: relative;
}
.testy h4 {
  font-size: 20px;
  color: #057e8c;
}

.texty div strong {
  font-size: 22px;
  color: #090524;
  font-family: "El Messiri" !important;
}
.texty div p {
  font-size: 16px;
  color: #090524;
}

.texty {
  position: absolute;
  bottom: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.texty img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}
.starSize {
  width: 30px;
  height: 30px;
}

.hCard {
  height: 350px;
  padding: 10px;
}

.roundIcon {
  margin-right: 20px;
  width: 60px;
  height: 60px;
  padding: 10px;
  border-radius: 50%;
  background-color: #090524;
}

.conty {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
}

.conty div strong {
  font-size: 22px;
  color: #fff;
  font-family: "El Messiri" !important;
}
.conty div p {
  font-size: 16px;
  color: #fff;
  font-family: "Montserrat" !important;
}
.letsWork {
  /* display: flex;
  gap: 10px;
  align-items: center; */
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.letsWork h1 {
  font-size: 80px;
  color: #fff;
  font-family: "El Messiri" !important;
}

.letsWork p {
  font-size: 20px;
  color: #fff;
  font-family: "Montserrat" !important;
}

@media (min-width: 200px) {
  .contCard {
    position: relative;
    width: 350px !important;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .footImg {
    display: flex;
    justify-content: center;
  }
  .footImg img {
    margin-top: 60px !important;
    margin-bottom: 50px !important;
    width: 300px !important;
  }

  .contFoot {
    height: auto !important;
    background-color: #090524;
    justify-content: center;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .letsWork {
    display: grid;
    justify-content: center !important;
  }
  .letsWork h1 {
    font-size: 40px;
  }

  .letsWork p {
    font-size: 10px;
    padding-left: auto !important;
    padding-right: auto !important;
  }
  .contCard h4 {
    font-size: 20px;
  }

  .newSize {
    width: 40px;
    height: 40px;
  }
}
@media (min-width: 576px) {
  .letsWork {
    display: grid;
    justify-content: center !important;
  }
  .letsWork h1 {
    font-size: 50px;
  }

  .letsWork p {
    font-size: 12px;
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .contFoot {
    height: auto !important;
    background-color: #090524;
    justify-content: center;
  }
  .contCard {
    position: relative;
    width: 350px !important;
    height: 300px !important;
  }

  .contCard h4 {
    position: relative;
    bottom: 20px;
    font-family: "El Messiri" !important;
  }

  .footImg img {
    margin-top: 50px;
    width: 800px !important;
  }
}

.contFoot {
  height: 800px;
  background-color: #090524;
}

.social {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  cursor: pointer;
}

.contCard {
  position: relative;
  width: 406px;
  height: 300px;
  background-color: transparent;
  border-radius: 4px;
  color: #fff;
  padding: 30px;
  border: 2px solid #fff;
  transition: box-shadow 0.3s ease;
  box-shadow: 0px 10px 20px rgba(9, 5, 36, 0.5);
}

/* // Small devices (landscape phones, 576px and up) */
/* @media (max-width: 820px) {
  .contCard {
    position: relative;
    width: 266px;
    height: 200px;
  }
} */

.contCard:hover {
  /* background-color: #ffffff; */
  box-shadow: 0px 10px 20px rgba(9, 5, 36, 0.5);
}

.contCard div {
  width: fit-content;
  height: fit-content;
  padding: 10px;
  border-radius: 50%;
  background-color: #ffffff;
}

.contCard h4 {
  position: absolute;
  bottom: 20px;
  font-family: "El Messiri" !important;
}

.contCard p {
  position: absolute;
  bottom: 5px;
  right: 30px;
}
.newSize {
  width: 50px;
  height: 50px;
}

.contCard h3 {
  font-size: 22px;
  color: #d10000;
  padding-top: 10px;
  font-family: "Montserrat" !important;
}

.footImg img {
  margin-top: 50px;
  width: fit-content;
}
.messi {
  font-family: "El Messiri" !important;
}
::selection {
  background-color: #d10000;
  color: #ffffff;
}
