


.back-drop-overlay{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #090524;
  opacity: 0.5;
}


.my-team{
 /* height: 100vh; */
 margin: 0px 0 20px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
} 

#team{

  background-color: whitesmoke !important;
  width: 100% !important;
  background-color: whitesmoke !important;
  padding: 60px 0 !important;
  padding-left: 70px !important;
  padding-right: 70px !important;
}


.slider-container{
  /* max-width: 1200px; */
  width: 100%;
  background-color: whitesmoke;
  padding: 40px 0;
}

.slider-content{
  margin: 0px 40px;
}

.cardozo{
background-color: #fff;
border-radius: 25px;
width: 320px;
}

.image-content,
.card-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 24px;
}

.image-content{
  row-gap: 5px;
  position: relative;

}

.overlay{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #090524;
  border-radius: 25px 25px 0px 25px;

}
.overlay::before,.overlay::after{
  content: '';
  position: absolute;
  right: 0;
  bottom: -40px;
  height: 40px;
  width: 40px;
  background-color: #090524;
}

.overlay::after{
  border-radius: 0 25px 0 0;
  background-color: #fff;
}

.modal-body{
  font-family: "Montserrat";
}
.card-image{
  position: relative;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-color: #fff;
  padding: 3px;

}

.card-image .card-img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid #090524;
  
  
}

.name{
  font-size: 18px;
  font-weight: 500;
  font-family: "Montserrat";
  font-weight: 600;
  color: #000000;
}
.post{
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}

.bio{
  font-size: 14px;
  color: #707070;
  text-align: center;
}

.socials{
  padding-left: 7px;
}